<template>
  <div class="px-5 py-2">
    <div class="flex flex-1 flex-col gap-2">
      <div class="flex flex-1 flex-col gap-4">
        <div class="text-xs font-semibold">Receive real-time alerts through our mobile app</div>
        <div class="flex flex-1 flex-col justify-between gap-2">
          <template v-if="page == 1">
            <div class="h-12 text-xs font-medium text-gray-100">
              1. Scan this QR Code with your iOS device to download The Tie SigDev Terminal App.
            </div>
            <div class="flex h-60 items-center justify-center">
              <VueQrcode
                class="h-40 w-40 rounded border border-black p-6"
                value="https://apps.apple.com/in/app/the-tie-sigdev-terminal/id1641937812"
                :width="206"
                :color="qrCodeColors"
                :margin="2"
              />
            </div>
          </template>
          <template v-else-if="page == 2">
            <div class="h-12 text-xs font-medium text-gray-100">2. In the app, scan this QR Code to login.</div>
            <div class="flex h-60 items-center justify-center">
              <QRDisplay class="flex flex-col" />
            </div>
          </template>
          <template v-else-if="page == 3">
            <div class="flex h-12 flex-col gap-4 text-xs font-medium text-gray-100">
              <div>3. Now you'll be able to receive alerts in our built-in messenger on both mobile and desktop.</div>
              <div>
                When creating an alert, you'll be able to select the messaging channel you'd like to send the alerts in.
              </div>
            </div>
            <div class="flex h-60 items-center justify-center">
              <img
                class="rounded border border-grid"
                style="width: 330px; height: 230px"
                src="../../../images/alert_mobile_delivery.png"
              />
            </div>
          </template>
        </div>
      </div>
      <BasePaginate v-model="page" :pages="3" class="mx-auto mb-5 mt-10 w-96" />
    </div>
    <BaseButton type="secondary" class="ml-auto mt-4" @press="emitter.$emit('cancel:destination')">Cancel</BaseButton>
  </div>
</template>

<script setup>
import colors from 'tailwindcss/colors';
import VueQrcode from 'vue-qrcode';
import QRDisplay from '@/components/settings/QRDisplay.vue';
import useEmitter from '@/composeables/emitter';
import { ref } from 'vue';

const emitter = useEmitter();

const page = ref(1);

const qrCodeColors = {
  dark: colors.slate['600'],
  light: colors.slate['900']
};
</script>
